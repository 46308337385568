import styled, { css, keyframes } from "styled-components";
import { Button } from "antd";
import { theme } from "../../styles/theme";

const DISABLED_FILLED = css`
  &&[disabled],
  &&[disabled]:hover {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray450};
    background-color: ${({ theme }) => theme.colors.gray350};
    border-color: transparent;
    box-shadow: none;
  }
`;

const vibrationAnimation = keyframes`
0%, 20%, 50%, 80%, 100% {
  transform: scale(1);
}
40% {
  transform: scale(0.8);
}
60% {
  transform: scale(0.9);
}
`;

export const CustomButton = styled(Button)`
  padding: ${({ p }) => p || "0 0 0 0"};
  width: ${({ width }) => width || "175px"};
  height: ${({ height }) => height || "48px"};
  background: ${({ background }) => background || theme.colors.green} 0% 0%
    no-repeat padding-box;
  border-radius: ${({ radius }) => radius || "25px"};
  opacity: 1;
  color: ${({ color }) => color || theme.colors.white};
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.default};
  font-weight: ${({ weight }) => weight || theme.fonts.weight.semibold};
  border: 1px solid;
  border-color: ${({ border }) => border || "transparent"};
  margin: ${({ m }) => m || "0 0 0 0"};

  animation: ${({ animate }) =>
    animate &&
    css`
      ${vibrationAnimation} 3s infinite
    `};

  && {
    &.ant-btn-link {
      &:hover {
        color: ${({ color }) => color || theme.colors.white};
      }
    }

    &.ant-btn-default {
      &:hover {
        color: ${({ color }) => color || theme.colors.white};
        background: ${theme.colors.orange} 0% 0% no-repeat padding-box;
      }
    }
  }

  ${DISABLED_FILLED}

  @media (max-width: 468px) {
    /* Ajusta el tamaño del botón para pantallas más pequeñas */
    width: 80%;
    font-size: ${(props) => props.theme.fonts.size.h3};
    white-space: normal; /* Permite que el texto se divida en varias líneas */
  }
`;
