import {
  SecurityScanOutlined,
  LikeOutlined,
  ReconciliationOutlined,
  BugOutlined,
} from "@ant-design/icons";
import { theme } from "../../styles/theme";

/* Informacion de los beneficios */
export const benefits = [
  {
    icon: (
      <SecurityScanOutlined
        style={{ fontSize: "60px", color: theme.colors.white }}
      />
    ),
    text: "Trazabilidad de los ingredientes",
  },
  {
    icon: (
      <BugOutlined style={{ fontSize: "60px", color: theme.colors.white }} />
    ),
    text: "Totalmente vegano",
  },
  {
    icon: (
      <LikeOutlined style={{ fontSize: "60px", color: theme.colors.white }} />
    ),
    text: "Los mejores extractos",
  },
  {
    icon: (
      <ReconciliationOutlined
        style={{ fontSize: "60px", color: theme.colors.white }}
      />
    ),
    text: "Sin testeo en animales",
  },
];

/* Información de los productos */
const openWhatsappChat = (kit) => {
  switch (kit) {
    case 1:
      window.open(`https://wa.link/4ww5cc`, "_blank");
      break;
    case 2:
      window.open(`https://wa.link/j68256`, "_blank");
      break;
    case 3:
      window.open(`https://wa.link/6ixnya`, "_blank");
      break;

    default:
      window.open(`https://wa.link/7luy7e`, "_blank");
      break;
  }
};

export const testimonials = [
  {
    name: "Andrea R.",
    testimony:
      "Nunca pensé que un desodorante pudiera hacer tanto. El G&H no solo me mantiene fresca, sino que también me quitó esas manchas feas de la ropa y de a poquito está eliminando la pigmentación de las axilas. Es mi salvador para sentirme confiada y sin afanes, ¡te lo juro!",
  },
  {
    name: "Maria T.",
    testimony:
      "La línea G&H es mi favorita de hace años. Tiene un aroma suave y de verdad cuida la piel. No me imagino mi rutina diaria sin esta vuelta. Se lo recomendaría a cualquiera que quiera lo mejor en cuidado personal",
  },
  {
    name: "Carlos M.",
    testimony:
      "Yo con mi piel sensible, siempre he batallado con los desodorantes. Pero el desodorante G&H es una bendición total. Es suave, pega fuerte y, lo mejor, es natural. Me siento seguro y fresco todo el día",
  },
  {
    name: "Sofia H.",
    testimony:
      "Como vegana, siempre estoy buscando productos naturales y de buena calidad. Este desodorante cumple con todos mis criterios. Además, ¡funciona! Mi confianza está por las nubes.",
  },
];
