import styled from "styled-components";
import { theme } from "../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ background }) => background || "transparent"};
  width: 100%;
  height: max-content;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height || null};
  width: ${({ width }) => width || null};
  margin: ${({ m }) => m || "0px 0px 0px 0px"};
  padding: ${({ p }) => p || "0px 0px 0px 0px"};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  gap: ${({ gap }) => gap || null};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  border-radius: ${({ radius }) => radius || "0px"};
  margin: ${({ m }) => m || "0px 0px 0px 0px"};
  padding: ${({ p }) => p || "0px 0px 0px 0px"};
  gap: ${({ gap }) => gap || null};
`;

export const Div = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || null};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  gap: ${({ gap }) => gap || null};
  margin: ${({ m }) => m || null};
  padding: ${({ p }) => p || null};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  min-width: ${({ minwidth }) => minwidth || null};
  min-height: ${({ minheight }) => minheight || null};
  max-width: ${({ maxwidth }) => maxwidth || null};
  max-height: ${({ maxheight }) => maxheight || null};
  flex-direction: ${({ direction }) => direction || null};
  border-radius: ${({ radius }) => radius || "0px"};
  background-color: ${({ background }) => background || "transparent"};
  @media (max-width: 468px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  font-family: ${theme.fonts.family};
  color: ${(props) => (props.color ? props.color : props.theme.colors.black)};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  text-align: ${(props) => props.align};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const Span = styled.span`
  font-family: ${theme.fonts.family};
  color: ${(props) => (props.color ? props.color : props.theme.colors.black)};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  padding: ${({ p }) => p || null};
  margin: ${({ m }) => m || null};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || null};
  background: ${({ background }) => background || theme.colors.white} 0% 0%
    no-repeat padding-box;
  box-shadow: 0 0 50px ${theme.colors.blue600};
  border-radius: 25px;
  opacity: 1;
`;

export const Grid = styled.div`
  margin: ${({ m }) => m || null};
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual ancho */
  gap: 20px;
  justify-content: center;
`;
